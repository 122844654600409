<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template v-else-if="jobTemplate">
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>
            Create New Job Template
          </h1>
        </div>
      </div>

      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Job Template Name</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder=""
                v-model="jobTemplate.name"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.jobTemplate.name.required"
            >
              Job template name is required
            </div>
          </div>

          <div class="field col-12">
            <label for="name">Description</label>

            <Editor
              ref="editor"
              placeholder="Description"
              id="Description"
              :autoResize="true"
              v-model="jobTemplate.description"
              editorStyle="height:150px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button><button class="ql-strike"></button>
                  <button
                    class="ql-list"
                    value="ordered"
                  ></button>
                  <button
                    class="ql-list"
                    value="bullet"
                  ></button>
                  <button class="ql-align"></button>
                  <button class="ql-link"></button>
                </span> </template>
            </Editor>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.jobTemplate.description.required"
            >
              Description is required
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="SAVE"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>

</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "JobTemplateSlider",
  components: {},
  data() {
    return {
      isLoading: false,
      submitted: false,
      jobTemplate: {
        name: null,
        description: null,
      },
    };
  },
  validations: {
    jobTemplate: {
      name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    obj() {
      return this.$store.getters.selectedObject;
    },
  },
  mounted() {
    this.jobTemplate = {
      name: this.obj.title,
      description: this.obj.description,
    };
    this.isLoading = false;
  },
  methods: {
    async submitHandler() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
        return;
      }

      if (!this.obj) {
        this.$toast.add({
          severity: "error",
          summary: "No object selected",
          life: 3000,
        });
        return;
      }

      const form = new FormData();
      form.append("name", this.jobTemplate.name || this.obj.name);
      form.append(
        "description",
        this.jobTemplate.description || this.obj.description
      );
      form.append("category", this.jobTemplate.category || this.obj.category);
      form.append("status", this.obj.status);
      form.append(
        "date_due",
        this.$moment(this.obj.due_date).format("YYYY-MM-DD")
      );
      form.append(
        "date_start",
        this.$moment(this.obj.start_date).format("YYYY-MM-DD")
      );

      form.append("budget_amount", this.obj.budget_amount ?? 0);
      form.append("client", this.obj.client.uid);
      form.append("managed_by", this.obj.managed_by.uid);
      form.append("contact", this.obj.client.contact);
      form.append("is_template", 1);
      const url = `/v1/jobs/${this.obj.uid}/clone`;

      try {
        const response = await this.$axios.post(
          process.env.VUE_APP_ROOT_API + url,
          form,
          {
            headers: { "Content-type": "multipart/form-data" },
          }
        );

        this.$toast.add({
          severity: "success",
          summary: "Template Saved",
          life: 3000,
        });
        const uid = response.data.data;

        if (uid) {
          this.clearSliderContent();
          this.$router.push(`/job/j${uid}`);
          this.$bus.emit("refreshData");
        } else {
          this.clearSliderContent();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized request!");
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error saving template",
            life: 3000,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>